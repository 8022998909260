import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { type Dispatch, type SetStateAction, useState } from "react";
import classNames from "classnames";

import EmptyView from "common/dashboard/empty_view/empty";
import { Hr } from "common/core/horizontal_rule";
import { DashboardContent, DashboardTitle } from "common/dashboard";
import { Paragraph, Heading } from "common/core/typography";
import Link from "common/core/link";
import { useQuery } from "util/graphql/query";
import LoadingIndicator from "common/core/loading_indicator";
import { Card, CardSection } from "common/core/card";
import { formatDate } from "util/date";
import { ButtonStyledLink } from "common/core/button/button_styled_link";
import Button from "common/core/button";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import Apps from "constants/applications";

import SamlProviderQuery, {
  type SamlProvider_organization_Organization_samlProviders as SamlProvider,
} from "./saml_providers.query.graphql";
import Styles from "./index.module.scss";
import { DeleteIdpModal, SamlProviderBadge } from "./common/common";
import { useContextOrParamsOrgId } from "../common";

const MESSAGES = defineMessages({
  noIdpTitle: {
    id: "8416e444-b02f-4206-b639-04d6d0417ebc",
    defaultMessage: "No identity providers configured yet",
  },
  noIdpDescriptionP1: {
    id: "72861ca0-9109-44e2-aeeb-f3689103c677",
    defaultMessage:
      'Click "Configure new identity provider" to create a new identity provider entry and set up your SAML configuration.',
  },
  noIdpDescriptionP2: {
    id: "72861ca0-9109-44e2-aeeb-f3689103c677",
    defaultMessage:
      "Your identity provider will need to be applied to a verified domain to enable SSO authentication.",
  },
  lastChecked: {
    id: "c0957615-9b48-40ad-b8c6-208033d60ad3",
    defaultMessage: "Last updated on {updateDate}",
  },
  configurationDetails: {
    id: "f5941f04-ea3c-4d92-bdb3-f201f40c45d5",
    defaultMessage: "Configuration details",
  },
  delete: {
    id: "cfdb3e6f-394e-446a-880d-17fad8eb2b7f",
    defaultMessage: "Delete",
  },
});

function SamlProviderTile({
  samlProvider,
  refetch,
}: {
  samlProvider: SamlProvider;
  refetch: () => void;
}) {
  const intl = useIntl();
  const isAdminPortal = CURRENT_PORTAL === Apps.ADMIN;
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const samlProviderId = samlProvider.id;

  return (
    <Card fullWidth automationId={`${samlProviderId}-card`}>
      <CardSection>
        <div className={Styles.idpCardTop}>
          <div>
            <div>
              <Heading
                className={Styles.idpHeading}
                level="h2"
                textStyle="headingFive"
                data-automation-id={`${samlProviderId}-title`}
                data-id={samlProviderId}
              >
                {samlProvider.name}
              </Heading>
              <SamlProviderBadge
                samlProviderId={samlProviderId}
                samlDomainsLength={samlProvider.domains.length}
              />
            </div>
            <div
              className={Styles.idpVerifiedAt}
              data-automation-id={`${samlProviderId}-last-updated`}
            >
              {intl.formatMessage(MESSAGES.lastChecked, {
                updateDate: formatDate(samlProvider.lastUpdated),
              })}
            </div>
          </div>

          <div className={Styles.idpButtonGroup}>
            {samlProvider.domains.length <= 0 && !isAdminPortal && (
              <Button
                variant="tertiary"
                buttonColor="danger"
                buttonSize="condensed"
                onClick={() => {
                  setShowDeleteModal(true);
                }}
              >
                {intl.formatMessage(MESSAGES.delete)}
              </Button>
            )}
            <ButtonStyledLink
              to={`${samlProviderId}/configuration`}
              automationId={`${samlProviderId}-configuration-button`}
              buttonColor="action"
              variant="secondary"
            >
              {intl.formatMessage(MESSAGES.configurationDetails)}
            </ButtonStyledLink>
          </div>
        </div>
      </CardSection>
      {showDeleteModal && (
        <DeleteIdpModal
          idp={samlProvider}
          setShowDeleteModal={setShowDeleteModal}
          onDeleteSuccess={() => refetch()}
        />
      )}
    </Card>
  );
}

function IdentityProvidersContent({
  setEmptyView,
}: {
  setEmptyView: Dispatch<SetStateAction<boolean>>;
}) {
  const intl = useIntl();
  const activeOrgId = useContextOrParamsOrgId();
  const { data, loading, refetch } = useQuery(SamlProviderQuery, {
    variables: { organizationId: activeOrgId },
  });

  if (loading) {
    return <LoadingIndicator />;
  }
  if (data?.organization?.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${data?.organization?.__typename}.`);
  }
  // filter out org based saml providers
  const samlProviders = data.organization.samlProviders.filter(
    (samlProvider: SamlProvider) => !(samlProvider.active && !samlProvider.domains.length),
  );
  setEmptyView(samlProviders.length === 0);

  return samlProviders.length > 0 ? (
    <>
      {samlProviders.map((samlProvider: SamlProvider) => (
        <SamlProviderTile samlProvider={samlProvider} key={samlProvider.id} refetch={refetch} />
      ))}
    </>
  ) : (
    <EmptyView title={intl.formatMessage(MESSAGES.noIdpTitle)}>
      <Paragraph>{intl.formatMessage(MESSAGES.noIdpDescriptionP1)}</Paragraph>
      <Paragraph>{intl.formatMessage(MESSAGES.noIdpDescriptionP2)}</Paragraph>
    </EmptyView>
  );
}

export function IdentityProviders() {
  const isAdminPortal = CURRENT_PORTAL === Apps.ADMIN;
  const [emptyView, setEmptyView] = useState(false);

  return (
    <div className={classNames({ [Styles.keystoneDashboardContent]: isAdminPortal })}>
      <DashboardContent>
        <DashboardTitle
          title={
            <FormattedMessage
              id="5e6196d9-4be8-4eff-a74a-d7441625f954"
              defaultMessage="Identity providers"
            />
          }
          // TODO - use correct "Learn more" link
          description={
            !isAdminPortal && (
              <div className={Styles.idpTitleDescription}>
                <FormattedMessage
                  id="63178af9-a250-40ed-992a-205acb98d526"
                  defaultMessage="Add your identity providers with SAML. Then, apply a configuration to a domain for domain-based authentication and provisioning policies. <link>Learn more</link> about identity providers and SAML configurations."
                  values={{
                    link: (text) => (
                      <Link
                        href={
                          "https://support.proof.com/hc/en-us/articles/8271646759959-Single-Sign-On-SSO"
                        }
                      >
                        {text}
                      </Link>
                    ),
                  }}
                />
              </div>
            )
          }
          buttons={
            !isAdminPortal && (
              <ButtonStyledLink
                to={`new`}
                automationId={"configure-new-idp"}
                variant={emptyView ? "primary" : "secondary"}
                buttonColor="action"
                openInCurrentTab
              >
                <FormattedMessage
                  id="6209f938-d6c7-42de-8e55-64bacca4e819"
                  defaultMessage="Configure new identity provider"
                />
              </ButtonStyledLink>
            )
          }
        />
        <Hr className={Styles.hr} />
        <IdentityProvidersContent setEmptyView={setEmptyView} />
      </DashboardContent>
    </div>
  );
}
