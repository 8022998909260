import { FormattedMessage } from "react-intl";
import { clearSubmitErrors, type InjectedFormProps, reduxForm, SubmissionError } from "redux-form";
import { useState } from "react";

import { useMutation } from "util/graphql";
import { DeprecatedDetailGrid } from "common/details/grid";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import { TransactionStatus } from "common/dashboard/columns";
import PaymentStatus from "common/transactions/payment_status";
import { usePermissions } from "common/core/current_user_role";
import Button from "common/core/button";
import { dateComparator } from "util/date";
import {
  MeetingEndedState,
  QualityControlDocumentIssuesEnum,
  QualityControlJournalIssuesEnum,
  QualityControlNotaryStatementIssuesEnum,
  QualityControlSignerIdIssuesEnum,
  QualityControlVideoIssuesEnum,
} from "graphql_globals";
import TextArea from "common/form/fields/text_area";
import { pushNotification } from "common/core/notification_center/actions";
import FormGroupErrors from "common/form/group_errors";
import type { FormError } from "errors/util";
import { customMessage } from "errors/form";
import { useDispatch } from "redux/util";

import DocumentDropdown from "./document_dropdown";
import type {
  QualityControlDocumentBundle as Bundle,
  QualityControlDocumentBundle_meetings_edges_node_meetingParticipants as MeetingParticipant,
} from "./quality_control_document_bundle_fragment.graphql";
import Styles from "./index.module.scss";
import SignerColumns from "./signer_section";
import MeetingSection from "./meeting_section";
import SubmitQualityControlMutation from "./submit_quality_control_mutation.graphql";

type SignerIdIssue = {
  idUnacceptablePerStateLaw: boolean | null;
  idUnacceptablePerDocumentRequirement: boolean | null;
  idIsACopyOfActualId: boolean | null;
  personDoesNotMatchPersonInKbaOrId: boolean | null;
  idNotClearlyVisibleOrCutOff: boolean | null;
  notaryAddedSignerWithAddWitnessFeature: boolean | null;
};

type VideoIssue = {
  notaryDidNotRequestSignerConsentToRecording: boolean | null;
  notaryDidNotAnnounceTypeOfNotarialActPerformedFL: boolean | null;
  notaryDidNotPromptSignerToChooseNotarialAct: boolean | null;
  signerDidNotAknowledgeTheyPresignedTheDocument: boolean | null;
  signerDidNotTakeOath: boolean | null;
  audioConnectionNotMaintainedForDurationOfSession: boolean | null;
  videoConnectionNotMaintainedForDurationOfSession: boolean | null;
  signerDidNotConfirmTheyVoluntarilySignedTheDocFL: boolean | null;
  witnessSignedWithoutActuallyWitnesingTheSigning: boolean | null;
};

type DocumentContentIssue = {
  documentFieldsToBeFilledBySignerAreLeftBlank: boolean | null;
  missingOrIncorrectSignerCapacityDoc: boolean | null;
  signerDidNotSignInFrontOfNotary: boolean | null;
  witnessLineNotProperlyAddressed: boolean | null;
  signerSignatureDiffersFromNameOnDoc: boolean | null;
  notarySignedOutsideNotarialStatement: boolean | null;
  missingDocumentPages: boolean | null;
};

type NotarialStatementIssue = {
  improperCompletionOfNotarialCertificate: boolean | null;
  undatedNotarialStatement: boolean | null;
  missingDisclosureStatement: boolean | null;
  notaryNameNotPrintedBelowNotarySignature: boolean | null;
  missingNotarySeal: boolean | null;
  missingNotaryVenue: boolean | null;
  missingSignerName: boolean | null;
  missingIdType: boolean | null;
  notarialCertificateWasSplitOnTwoPages: boolean | null;
  missingDescriptionOfNotarialAct: boolean | null;
  notarySealObstructed: boolean | null;
  notarySealNotFullyVisible: boolean | null;
  extraNotarySeal: boolean | null;
  missingOrIncorrectSignerCapacityStatement: boolean | null;
  signersNameInNotarialStatementDiffersFromNameOnDoc: boolean | null;
  notaryNotarizedForIndividualNotInSession: boolean | null;
  missingNotaryTitle: boolean | null;
  missingNotarySignature: boolean | null;
  looseLeafNotarialActDiffersFromOneOnDoc: boolean | null;
  notaryNotarizedForIndividualWhoDidNotSignTheDoc: boolean | null;
};

type JournalIssue = {
  notarialActRecordedInJournalDiffersFromOneInNotarialStatement: boolean | null;
};

type DocumentIssue = {
  documentIssues: DocumentContentIssue | null;
  notaryStatementIssues: NotarialStatementIssue | null;
  journalIssues: JournalIssue | null;
};

type FormValues = {
  signerIdIssues: Record<string, SignerIdIssue> | null;
  videoIssues: Record<string, VideoIssue> | null;
  documentIssues: Record<string, DocumentIssue> | null;
  notes: string;
};

type Props = { bundle: Bundle; notaryId: string; meetingId?: string; transactionId?: string };
type InnerProps = InjectedFormProps<FormValues, Props> & Props;

const incompleteFormError = (location: string) => {
  throw new SubmissionError<{ submitError: unknown }, FormError>({
    [location]: customMessage({
      message: (
        <FormattedMessage
          id="4a17fd21-8518-4382-8373-0d5e7f574233"
          defaultMessage="Please select at least one response"
        />
      ),
    }),
    submitError: customMessage({
      message: (
        <FormattedMessage
          id="2ff0cce6-d3e3-4e15-805e-3ad08d7f5330"
          defaultMessage="Please select at least one response per section"
        />
      ),
    }),
  });
};

const setupSignerIssues = (signerIssues: SignerIdIssue): QualityControlSignerIdIssuesEnum[] => {
  return [
    signerIssues.idIsACopyOfActualId &&
      QualityControlSignerIdIssuesEnum.SIGNER_ID_IS_A_COPY_OF_AN_ACTUAL_ID,
    signerIssues.idUnacceptablePerDocumentRequirement &&
      QualityControlSignerIdIssuesEnum.SIGNER_ID_UNACCEPTABLE_PER_DOCUMENT_REQUIREMENTS,
    signerIssues.idUnacceptablePerStateLaw &&
      QualityControlSignerIdIssuesEnum.SIGNER_ID_UNACCEPTABLE_PER_STATE_LAW,
    signerIssues.personDoesNotMatchPersonInKbaOrId &&
      QualityControlSignerIdIssuesEnum.PERSON_DOES_NOT_MATCH_ID_AND_KBA,
    signerIssues.idNotClearlyVisibleOrCutOff &&
      QualityControlSignerIdIssuesEnum.SIGNER_ID_NOT_CLEARLY_VISABLE_OR_CUT_OFF,
    signerIssues.notaryAddedSignerWithAddWitnessFeature &&
      QualityControlSignerIdIssuesEnum.NOTARY_ADDED_SIGNER_WITH_ADD_WITNESS_FEATURE,
  ].filter(Boolean);
};

const setupVideoQualityControlIssues = (videoIssues: VideoIssue) => {
  return [
    videoIssues.signerDidNotAknowledgeTheyPresignedTheDocument &&
      QualityControlVideoIssuesEnum.SIGNER_DID_NOT_ACKNOWLEDGE_THEY_SIGNED_THE_DOCUMENT,
    videoIssues.signerDidNotConfirmTheyVoluntarilySignedTheDocFL &&
      QualityControlVideoIssuesEnum.SIGNER_DID_NOT_CONFIRM_THEY_KNOWINGLY_AND_WILLINGLY_SIGNED_FL,
    videoIssues.signerDidNotTakeOath && QualityControlVideoIssuesEnum.SIGNER_DID_NOT_TAKE_OATH,
    videoIssues.notaryDidNotAnnounceTypeOfNotarialActPerformedFL &&
      QualityControlVideoIssuesEnum.NOTARY_DID_NOT_ANNOUNCE_TYPE_OF_NOTARIAL_ACT_PERFORMED_FL,
    videoIssues.notaryDidNotPromptSignerToChooseNotarialAct &&
      QualityControlVideoIssuesEnum.NOTARY_DID_NOT_PROMPT_SIGNER_TO_CHOOSE_NOTARIAL_ACT,
    videoIssues.notaryDidNotRequestSignerConsentToRecording &&
      QualityControlVideoIssuesEnum.NOTARY_DID_NOT_REQUEST_SIGNERS_CONSENT_TO_RECORDING,
    videoIssues.audioConnectionNotMaintainedForDurationOfSession &&
      QualityControlVideoIssuesEnum.AUDIO_CONNECTION_NOT_MAINTAINED_FOR_DURATION_OF_THE_SESSION,
    videoIssues.videoConnectionNotMaintainedForDurationOfSession &&
      QualityControlVideoIssuesEnum.VIDEO_CONNECTION_NOT_MAINTAINED_FOR_DURATION_OF_THE_SESSION,
    videoIssues.witnessSignedWithoutActuallyWitnesingTheSigning &&
      QualityControlVideoIssuesEnum.WITNESS_SIGNED_WITHOUT_WITNESSING_THE_SIGNING,
  ].filter(Boolean);
};

const setupJournalIssues = (journalIssues: JournalIssue) => {
  return journalIssues.notarialActRecordedInJournalDiffersFromOneInNotarialStatement
    ? [
        QualityControlJournalIssuesEnum.NOTARIAL_ACT_RECORDED_IN_JOURNAL_DIFFERS_FROM_NOTARIAL_STATEMENT,
      ]
    : [];
};

const setupDocumentIssues = (documentIssue: DocumentContentIssue) => {
  return [
    documentIssue.documentFieldsToBeFilledBySignerAreLeftBlank &&
      QualityControlDocumentIssuesEnum.DOCUMENT_FIELDS_FOR_SIGNER_ARE_LEFT_BLANK,
    documentIssue.signerSignatureDiffersFromNameOnDoc &&
      QualityControlDocumentIssuesEnum.SIGNERS_SIGNATURE_DIFFERS_FROM_NAME_ON_DOC,
    documentIssue.signerDidNotSignInFrontOfNotary &&
      QualityControlDocumentIssuesEnum.SIGNER_DID_NOT_SIGN_IN_FRONT_OF_NOTARY_JURAT,
    documentIssue.notarySignedOutsideNotarialStatement &&
      QualityControlDocumentIssuesEnum.NOTARY_SIGNED_OUTSIDE_OF_NOTARIAL_STATEMENT,
    documentIssue.missingOrIncorrectSignerCapacityDoc &&
      QualityControlDocumentIssuesEnum.MISSING_OR_INCORRECT_SIGNER_CAPACITY_DOCUMENT,
    documentIssue.witnessLineNotProperlyAddressed &&
      QualityControlDocumentIssuesEnum.WITNESS_LINE_NOT_PROPERLY_ADDRESSED,
    documentIssue.missingDocumentPages && QualityControlDocumentIssuesEnum.MISSING_DOCUMENT_PAGES,
  ].filter(Boolean);
};

const setupNotaryStatementIssues = (notaryStatementIssue: NotarialStatementIssue) => {
  return [
    notaryStatementIssue.improperCompletionOfNotarialCertificate &&
      QualityControlNotaryStatementIssuesEnum.IMPROPER_COMPLETION_OF_NOTARIAL_CERTIFICATE,
    notaryStatementIssue.undatedNotarialStatement &&
      QualityControlNotaryStatementIssuesEnum.UNDATED_NOTARIAL_STATEMENT,
    notaryStatementIssue.missingDisclosureStatement &&
      QualityControlNotaryStatementIssuesEnum.MISSING_DISCLOSURE_STATEMENT,
    notaryStatementIssue.notaryNameNotPrintedBelowNotarySignature &&
      QualityControlNotaryStatementIssuesEnum.NOTARY_NAME_NOT_PRINTED_BELOW_NOTARY_STATEMENT,
    notaryStatementIssue.missingNotarySeal &&
      QualityControlNotaryStatementIssuesEnum.MISSING_NOTARY_SEAL,
    notaryStatementIssue.missingNotarySignature &&
      QualityControlNotaryStatementIssuesEnum.MISSING_NOTARY_SIGNATURE,
    notaryStatementIssue.missingSignerName &&
      QualityControlNotaryStatementIssuesEnum.MISSING_SIGNER_NAME,
    notaryStatementIssue.missingNotaryVenue &&
      QualityControlNotaryStatementIssuesEnum.MISSING_NOTARY_VENUE,
    notaryStatementIssue.missingIdType && QualityControlNotaryStatementIssuesEnum.MISSING_ID_TYPE,
    notaryStatementIssue.notarialCertificateWasSplitOnTwoPages &&
      QualityControlNotaryStatementIssuesEnum.NOTARIAL_CERTIFICATE_WAS_SPLIT_ON_TWO_PAGES,
    notaryStatementIssue.missingDescriptionOfNotarialAct &&
      QualityControlNotaryStatementIssuesEnum.MISSING_DESCRIPTION_OF_NOTARIAL_ACT,
    notaryStatementIssue.notarySealObstructed &&
      QualityControlNotaryStatementIssuesEnum.NOTARY_SEAL_OBSTRUCTED,
    notaryStatementIssue.notarySealNotFullyVisible &&
      QualityControlNotaryStatementIssuesEnum.NOTARY_SEAL_NOT_FULLY_VISIBLE,
    notaryStatementIssue.looseLeafNotarialActDiffersFromOneOnDoc &&
      QualityControlNotaryStatementIssuesEnum.LOOSE_LEAF_NOTARIAL_ACT_DIFFERS_FROM_DOCUMENT,
    notaryStatementIssue.extraNotarySeal &&
      QualityControlNotaryStatementIssuesEnum.EXTRA_NOTARY_SEAL,
    notaryStatementIssue.missingOrIncorrectSignerCapacityStatement &&
      QualityControlNotaryStatementIssuesEnum.MISSING_OR_INCORRECT_SIGNER_CAPACITY_STATEMENT,
    notaryStatementIssue.signersNameInNotarialStatementDiffersFromNameOnDoc &&
      QualityControlNotaryStatementIssuesEnum.SIGNER_NAME_ON_NOTARIAL_STATEMENT_DIFFERS_FROM_NAME_ON_DOC,
    notaryStatementIssue.notaryNotarizedForIndividualNotInSession &&
      QualityControlNotaryStatementIssuesEnum.NOTARY_NOTARIZED_FOR_INDIVIDUAL_NOT_IN_SESSION,
    notaryStatementIssue.missingNotaryTitle &&
      QualityControlNotaryStatementIssuesEnum.MISSING_NOTARY_TITLE,
    notaryStatementIssue.notaryNotarizedForIndividualWhoDidNotSignTheDoc &&
      QualityControlNotaryStatementIssuesEnum.NOTARY_NOTARIZED_FOR_INDIVIDUAL_WHO_DID_NOT_SIGN_DOC,
  ].filter(Boolean);
};

function QualityControlForm({
  handleSubmit,
  bundle,
  notaryId,
  meetingId,
  transactionId,
}: InnerProps) {
  const submitQualityControlMutation = useMutation(SubmitQualityControlMutation);
  const { hasPermissionFor } = usePermissions();
  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch();

  const meetings = bundle.meetings.edges
    .filter(
      ({ node }) =>
        node.publicNotaryDetails?.id === notaryId &&
        node.endedState === MeetingEndedState.COMPLETED,
    )
    .sort((a, b) => {
      return dateComparator(b.node.timeFrame?.startedAt || "", a.node.timeFrame?.startedAt || "");
    });

  const documents = bundle.documents.edges.map((document) => document.node);

  const allMeetingParticipants = meetings.map((meeting) => meeting.node.meetingParticipants);

  const meetingParticipants = ([] as MeetingParticipant[]).concat(...allMeetingParticipants);

  const signerParticipants = meetingParticipants.filter(
    (meetingParticipant) => meetingParticipant.__typename === "SignerParticipant",
  );

  const publicNotaryDetails = meetings[0].node.publicNotaryDetails!;

  const serializeForm = (fv: FormValues) => {
    dispatch(clearSubmitErrors("adminQualityControlForm"));
    const formatSignerParticipants = signerParticipants.map((participant) => {
      const correspondingFormVal = fv.signerIdIssues?.[participant.id];
      let qualityIssues = [] as QualityControlSignerIdIssuesEnum[];
      if (!correspondingFormVal || !Object.values(correspondingFormVal).length) {
        incompleteFormError(`signerIdIssues_${participant.id}`);
      }
      if (correspondingFormVal) {
        qualityIssues = setupSignerIssues(correspondingFormVal);
      }

      return { signerIdentityId: participant.signerIdentityId, qualityIssues };
    });

    const formatVideoIssues = meetings.map((meeting) => {
      const correspondingFormVal = fv.videoIssues?.[meeting.node.id];
      const qualityIssues = [] as QualityControlVideoIssuesEnum[];
      if (!correspondingFormVal || !Object.values(correspondingFormVal).length) {
        incompleteFormError(`videoIssues_${meeting.node.id}`);
      }
      if (correspondingFormVal) {
        qualityIssues.push(...setupVideoQualityControlIssues(correspondingFormVal));
      }
      return { meetingId: meeting.node.id, qualityIssues };
    });

    const formatDocumentIssues = documents
      .filter((doc) => doc.notarialActs.length)
      .map((doc) => {
        const correspondingFormVal = fv.documentIssues?.[doc.id];
        const notaryStatementIssues = [] as QualityControlNotaryStatementIssuesEnum[];
        const documentContentIssues = [] as QualityControlDocumentIssuesEnum[];
        const journalIssues = [] as QualityControlJournalIssuesEnum[];

        if (
          !correspondingFormVal?.documentIssues ||
          !Object.values(correspondingFormVal.documentIssues).length
        ) {
          incompleteFormError(`documentIssues_${doc.id}_documentIssues`);
        }

        if (
          !correspondingFormVal?.notaryStatementIssues ||
          !Object.values(correspondingFormVal.notaryStatementIssues).length
        ) {
          incompleteFormError(`documentIssues_${doc.id}_notaryStatementIssues`);
        }

        if (
          !correspondingFormVal?.journalIssues ||
          !Object.values(correspondingFormVal.journalIssues).length
        ) {
          incompleteFormError(`documentIssues_${doc.id}_journalIssues`);
        }

        if (correspondingFormVal?.journalIssues) {
          journalIssues.push(...setupJournalIssues(correspondingFormVal.journalIssues));
        }
        if (correspondingFormVal?.documentIssues) {
          documentContentIssues.push(...setupDocumentIssues(correspondingFormVal.documentIssues));
        }
        if (correspondingFormVal?.notaryStatementIssues) {
          notaryStatementIssues.push(
            ...setupNotaryStatementIssues(correspondingFormVal.notaryStatementIssues),
          );
        }
        return {
          documentId: doc.id,
          journalIssues,
          documentIssues: documentContentIssues,
          notaryStatementIssues,
        };
      });

    submitQualityControlMutation({
      variables: {
        input: {
          documentBundleId: bundle.id,
          notaryProfileId: notaryId,
          signerIssues: formatSignerParticipants,
          videoIssues: formatVideoIssues,
          documentIssues: formatDocumentIssues,
        },
      },
    })
      .catch((error: Error) => {
        pushNotification({ subtype: "ERROR", type: "DEFAULT", message: error.message });
        throw error;
      })
      .then(() => {
        pushNotification({
          type: "DEFAULT",
          title: (
            <FormattedMessage id="723147ad-7a9f-4701-92e3-bac89d9ae691" defaultMessage="Success" />
          ),
          message: (
            <FormattedMessage
              id="430ed580-edd4-465f-8693-236b60c5c955"
              defaultMessage="{type} QC {id} was submited."
              values={{
                id: transactionId || meetingId,
                type: transactionId ? (
                  <FormattedMessage
                    id="1795533f-0b7a-4e6d-949a-586c23573311"
                    defaultMessage="Transaction"
                  />
                ) : (
                  <FormattedMessage
                    id="9be63f09-2ed2-4798-b6bc-cc5fe1f64ad3"
                    defaultMessage="Meeting"
                  />
                ),
              }}
            />
          ),
        });
        setSubmitted(true);
      });
  };

  return (
    <div>
      <div className={Styles.formColumns}>
        <div className={Styles.info}>
          <div className={Styles.sectionTitle}>
            <FormattedMessage
              id="e3a6e4e7-5e0c-4034-9343-50fa6a1b3948"
              defaultMessage="Summary of Meeting Details"
              tagName="h2"
            />
          </div>
          <DeprecatedDetailGrid>
            <DeprecatedDetailGridRow
              className={Styles.row}
              title={
                <div className={Styles.rowTitle}>
                  <FormattedMessage
                    id="b390927e-3146-4ed1-81f8-eeec79e6fd2d"
                    defaultMessage="Notarize ID"
                  />
                </div>
              }
            >
              {bundle.retrievalId}
            </DeprecatedDetailGridRow>
            <DeprecatedDetailGridRow
              className={Styles.row}
              title={
                <div className={Styles.rowTitle}>
                  <FormattedMessage
                    id="de546f47-8d10-4ce2-b926-f1cefbbaa633"
                    defaultMessage="Access Pin"
                  />
                </div>
              }
            >
              {bundle.retrievalPin}
            </DeprecatedDetailGridRow>
            <DeprecatedDetailGridRow
              className={Styles.row}
              title={
                <div className={Styles.rowTitle}>
                  <FormattedMessage
                    id="c9877ac4-e5b1-44ef-9745-d53faa78245f"
                    defaultMessage="Status"
                  />
                </div>
              }
            >
              <TransactionStatus
                status={bundle.transaction.status}
                detailedStatus={bundle.transaction.detailedStatus}
              />
            </DeprecatedDetailGridRow>

            <PaymentStatus
              bundle={bundle}
              adminCapabilities={hasPermissionFor("paymentStatus")}
              customTitle={
                <div className={Styles.rowTitle}>
                  <FormattedMessage
                    id="67904d72-cf76-40f2-924a-40c39f3ffde2"
                    defaultMessage="Payments"
                  />
                </div>
              }
            />
          </DeprecatedDetailGrid>
        </div>
        <div className={Styles.questions} />
      </div>
      <SignerColumns
        signerParticipants={signerParticipants}
        meetingParticipants={meetingParticipants}
        completionRequirements={bundle.completionRequirements || []}
        notaryState={publicNotaryDetails.usStateName}
      />
      <MeetingSection meetings={meetings.map((meeting) => meeting.node)} />

      <div className={Styles.formColumns}>
        <div className={Styles.info}>
          <div className={Styles.sectionTitle}>
            <FormattedMessage
              id="0e75bce4-177b-467c-9765-393b46b37285"
              defaultMessage="Documents"
            />
          </div>
          {documents.map((document) => (
            <DocumentDropdown key={document.id} document={document} />
          ))}
          <div className={Styles.sectionTitle}>
            <FormattedMessage id="c7b5cf21-292a-482b-a7cb-fd70b4ae820f" defaultMessage="Notes" />
          </div>

          <TextArea className={Styles.notes} name="notes" />
        </div>
        <div className={Styles.questions}>
          <div className={Styles.questionTitle}>
            <FormattedMessage
              id="68d30c7b-a215-4a44-8d7e-1e3b91fbd053"
              defaultMessage="Document & Notary Issues"
            />
          </div>
          <div className={Styles.documentText}>
            <FormattedMessage
              id="0430fb9a-c244-4cff-b4f1-a92b069d44e4"
              defaultMessage="Open the modals below to view the document and the questions"
            />
          </div>
          <div className={Styles.submit}>
            <Button
              disabled={submitted}
              className={Styles.submitButton}
              buttonColor="action"
              variant="primary"
              onClick={handleSubmit(serializeForm)}
            >
              Submit
            </Button>
            <FormGroupErrors fields={["submitError"]} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default reduxForm<FormValues, Props>({
  form: "adminQualityControlForm",
})(QualityControlForm);
